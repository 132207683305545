<template>
  <v-theme-provider light>
    <base-section
      id="about"
      class="accent text-center"
    >
      <div class="bg-pixel" />
      <base-section-heading
        class="primary--text pt-16"
        color="transparent"
        title="Probelektion"
      />
      <v-container class="pr-16 pl-16">
        <h3

          class="text-h5 font-weight-bold mb-4 text-center"
        >
          Du bist interessiert?
          Dann meld dich an und tanze mit uns mit!
          <br><br>
          Schnupperkurse sind Kostenlos.
          Melde dich kurz im Vorfeld mit Namen, Datum und Zeit und welchen Kurs du besuchen kommst.
          <br><br>
          Wir freuen uns auf dich!
        </h3>
        <div class="py-4" />
        <h3

          class="text-h5 font-weight-bold primary--text mb-4 text-center"
        >
          Björn Buz Meier:
        </h3>
        <div class="py-4" />
        <h3

          class="text-h5 font-weight-bold mb-4 text-center"
        >
          Tel. 079 689 07 31 <br>
          <a href="mailto:buzbreakdance@gmx.ch">buzbreakdance@gmx.ch</a>
        </h3>
      </v-container>
      <div class="py-16" />
      <div class="bg-pixel-down" />
    </base-section>
  </v-theme-provider>
</template>

<script>

  export default {
    name: 'SectionNews',
    provide: {
      heading: { align: 'center' },
    },
  }
</script>

<style scoped>
.v-application .accent{
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.bg-pixel{
  background-image:url('~@/assets/gradient-black.png');
  background-repeat: repeat-x;
  height: 80px;
  background-size:contain;
width:100%;
position:absolute;
z-index: 3;
}

.bg-pixel-down {
  height: 80px;

  background-image:url('~@/assets/gradient_red.png');
  background-repeat: repeat-x;
  background-size:contain;
  transform: scaleY(-1);
  width:100%;
  position:absolute;
  z-index: 3;
}
</style>
